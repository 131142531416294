<template>
  <div>
    <v-dialog v-model="shown" persistent>
      <template #default>
        <v-card
          class="mx-auto px-6 py-8"
          :min-width="$vuetify.display.mobile ? '320px' : '420px'"
        >
          <v-card-title class="headline d-flex justify-center mb-2"
            ><i><strong>Beta Phase</strong></i></v-card-title
          >
          <v-form v-model="form" fast-fail @submit.prevent="onSubmit">
            <v-text-field
              v-model="firstName"
              data-cy="firstname"
              :readonly="loading"
              :rules="[rules.required]"
              class="mb-2"
              clearable
              label="Prénom"
            ></v-text-field>

            <v-text-field
              v-model="lastName"
              data-cy="lastname"
              :readonly="loading"
              :rules="[rules.required]"
              clearable
              label="Nom"
            ></v-text-field>
            <v-text-field
              v-model="email"
              data-cy="email"
              :readonly="loading"
              :rules="[rules.email]"
              class="mb-2"
              clearable
              label="Email"
              type="email"
            ></v-text-field>
            <v-checkbox
              v-model="termsOfUse"
              :readonly="loading"
              data-cy="termsOfUse"
              density="compact"
              :rules="[rules.required]"
            >
              <template #label>
                <div>
                  J'accepte les

                  <a href="/termsofuse" target="_blank" @click.stop>
                    conditions d'utilisation
                  </a>
                </div>
              </template></v-checkbox
            >

            <v-btn
              :disabled="!form"
              :loading="loading"
              data-cy="signupBtn"
              block
              color="primary"
              transition="fade"
              size="large"
              type="submit"
              variant="elevated"
            >
              S'inscrire
            </v-btn>
          </v-form>
          <v-divider class="my-4"><strong>Ou</strong></v-divider>
          <v-btn
            :loading="loading"
            block
            data-cy="signinBtn"
            color="primary"
            transition="fade"
            size="large"
            variant="elevated"
            @click="onClickSignIn"
            >Déjà inscrit</v-btn
          >
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { Meteor } from 'meteor/meteor';
import log from 'loglevel';
import { useRouter, useRoute } from 'vue-router';
import { captchalogin } from '/imports/api/methods/Connect';

//import { captcharegister } from "../../api/methods/Connect";
import { useReCaptcha } from 'vue-recaptcha-v3';
import { useNotifier } from 'vuetify-notifier';
const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();
//import VueRecaptcha from "vue-recaptcha";

const router = useRouter();
const route = useRoute();
const $notifier = useNotifier();

let firstName = ref('');
let lastName = ref('');
let email = ref('');
let loading = ref(false);
let shown = ref(true);
let form = ref(null);
let termsOfUse = ref(false);

let rules = {
  required: (value) => !!value || 'Obligatoire',
  email: (value) => {
    if (!value) return 'Obligatoire';
    if (!/^.+@.+\..+$/.test(value)) return 'Email invalide';
    return true;
  },
  password: (value) => {
    if (!value) return 'Obligatoire';
    if (!/[A-Z]/.test(value))
      return 'Le mot de passe doit contenir au moins une majuscule';
    if (!/[a-z]/.test(value))
      return 'Le mot de passe doit contenir au moins une minuscule';
    if (!/[0-9]/.test(value))
      return 'Le mot de passe doit contenir au moins un chiffre';
    if (!/[#?!@$%^&*-]/.test(value))
      return 'Le mot de passe doit contenir au moins un caractère spécial';
    if (value.length < 10)
      return 'Le mot de passe doit contenir au moins 10 caractères';
    return true;
  }
};

/*   validations: {
    email: {
      required,
      email,
      minLength: minLength(3)
    },
    firstname: { required },
    lastname: { required },
    passwordrepeat: { required, sameAsPassword: sameAs('password') },
    password: {
      required,
      minLength: minLength(8),
      // minLength: minLength(8)  // I assume you'd want something like this too
      containsUppercase: function (value) {
        return /[A-Z]/.test(value);
      },
      containsLowercase: function (value) {
        return /[a-z]/.test(value);
      },
      containsNumber: function (value) {
        return /[0-9]/.test(value);
      },
      containsSpecial: function (value) {
        return /[#?!@$%^&*-]/.test(value);
      }
    } */

async function onSubmit() {
  log.info('PAssword OK');
  if (!form.value) return;

  loading.value = true;
  try {
    await recaptchaLoaded();
  } catch (error) {
    log.info('Error Captcha', error);
    // messageError.value = 'Captcha error';
    loading.value = false;
  }

  log.info('PAssword OK');
  //Execute reCAPTCHA with action "login".
  //const token = await executeRecaptcha('login');

  let token;
  try {
    token = await executeRecaptcha('register');
  } catch (error) {
    log.info('Error Captcha', error);
    // messageError.value = 'Captcha error';
    loading.value = false;
  }
  log.info('PAssword OK');
  captchalogin
    .callAsync(
      /*  Meteor.call(
        'captcha.login', */
      {
        token
      }
    )
    .then(() => {
      Meteor.callAsync('create.user', {
        email: email.value,
        termsOfUse: termsOfUse.value,
        firstName: firstName.value,
        lastName: lastName.value
      })
        .then(() => {
          log.info('OK Register');
          $notifier
            .alertSuccess({
              title: 'Inscription réussie',
              text: 'Vous allez recevoir un email pour activer votre compte'
            })
            .then(() => {
              const path = `/`;
              if (route.path !== path) router.push(path);
              // this.$router.push(path);
            });
        })
        .catch((error) => {
          log.info('Error Register', error);
          // messageError.value = 'Captcha error';
          loading.value = false;
        });
    });
}

function onClickSignIn() {
  router.push(`/signin`);
}
</script>

<style lang="scss" scoped>
.v-overlay {
  background-image: url('/bg.png');
  background-size: cover;
}
div.check {
  margin-top: -12px;
  padding: 0px 0px 0px 12px;
  color: red;
  font-size: 80%;
}
div.checkwhite {
  margin-top: -12px;
  color: #1e2833;
  font-size: 80%;
}
</style>
